

















































import { Component, Vue } from "vue-property-decorator";
import AuthService from "../../services/AuthService";
// import { Getter, Mutation } from 'vuex-class';
import { mapActions, mapGetters } from "vuex";
import AuthUser from "../../../global-config/AuthUser";
import AlertService from "../../../common-app/service/AlertService";

@Component({
  // computed: mapGetters(['userId','verifycode']),
})
export default class Verify extends Vue {
  public code: string = "";
  public submitted: boolean = false;
  public isResend:boolean=false;
  created() {
    // console.log(this.$route.query.code); // -> Hello
    // console.log(this.$store.getters.userId); // -> Hello
    const dict = {
      custom: {
        code: {
          required: "The code is required"
        }
      }
    };
    this.$validator.localize("en", dict);
  }

  send() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submitted = true;
        if (this.$store.getters.verifycode === this.code) {
          AuthService.verify({
            userId: this.$store.getters.userId,
            code: this.code,
          })
            .then((response) => {
              if (response.data) {
                this.$store.dispatch("addVerify", {
                  userId: this.$store.getters.userId,
                  verifycode: this.code,
                });
                AuthUser.setToken(response.data);
                this.$router.push({ path: "registration-confirmation" });
              } else {
                this.submitted = false;
                AlertService.sendMessage({status:false,message:"The verification code is not valid"});
              }
            })
            .catch((dd) => {
              var sf = "";
            });
        } else {
          this.submitted = false;
          AlertService.sendMessage({ status: false, message: "The verification code is not valid" });
        }
      }
    });
  }

  ReSend() {
    this.isResend=true;
    AuthService.ReSendCode({
      userId: this.$store.getters.userId,
      code: this.$store.getters.verifycode,
    }).then((response) => {
      if (response.data.status) {
        this.$store.dispatch("addVerify", {
          userId: this.$store.getters.userId,
          verifycode: response.data.result,
        });
        AlertService.sendMessage(response.data);
            this.isResend=false;
      } else {
        AlertService.sendMessage(response.data);
            this.isResend=false;
      }
    });
  }

  // add(){
  //   this.$store.dispatch('addVerify',{userId: 5, verifycode:'sdasdas'})
  //   console.log(this.$store.getters.userId)
  // }
}
