import { UnAuthAxios } from "../../global-config/HttpConfig";
import { LoginViewModel } from "../models/LoginViewModel";
import { RegViewModel } from "../models/RegViewModel";
import { ApiURIs } from "../../global-config/ApiList";
// Define a type for the Google login response
interface GoogleLoginResponse {
  isAuthorized: boolean;
  token?: string; // Optional token if applicable
  message?: string; // Optional message for error handling
}
export default {
  login(user: LoginViewModel) {
    return UnAuthAxios.post(ApiURIs.login, JSON.stringify(user));
  },
  registration(user: RegViewModel) {
    return UnAuthAxios.post(ApiURIs.register, JSON.stringify(user));
  },
  verify(data: any) {
    return UnAuthAxios.post(ApiURIs.verify, JSON.stringify(data));
  },
  ReSendCode(data: any) {
    return UnAuthAxios.post(ApiURIs.reSendCode, JSON.stringify(data));
  },
  resetPasswordVerify(password: string, data: any) {
    return UnAuthAxios.post(
      `${ApiURIs.resetPasswordVerify}?password=${password}`,
      JSON.stringify(data)
    );
  },
  setPasswordGuest(password: string, data: any) {
    return UnAuthAxios.post(
      `${ApiURIs.setPasswordGuest}?password=${password}`,
      JSON.stringify(data)
    );
  },
  resetPassword(phoneOrEmail: string) {
    return UnAuthAxios.get(
      ApiURIs.resetPassword + "?phoneOrEmail=" + phoneOrEmail
    );
  },
  test() {
    return UnAuthAxios.get("api/user/test");
  },
   // Google login method
   googleLogin(data: { code: string,isLogIn:boolean }) {
    return UnAuthAxios.post(ApiURIs.googleLogin, data);     
},


 // Facebook login method

 facebookLogin(data: { accessToken: string; name: string; email: string }) {
  return UnAuthAxios.post(ApiURIs.facebookLogin, data);
}


};


